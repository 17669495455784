import React from 'react';
import { Button, CloseIcon, Input } from '@avila-tek/ui';
import NProgress from 'nprogress';
import jsPDF from 'jspdf';
import axios from 'axios';
import { validateEmail } from 'avilatek-utils';
import { v4 as uuid } from 'uuid';
import { useMutation } from '@apollo/client';
import { TailSpin } from 'react-loader-spinner';
import { SIGN_S3 } from '../../../../graphql/mutation';
import { useNotify } from '../../../../hooks';
import { generateDocDefinition } from '../../../../lib/generateDocDefinition';
import { Order } from '../../../../models';
import Modal from '../../../common/Modal';

interface RequestSignaturesModalProps {
  isOpen?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  document?: string;
  name?: string;
  order?: Order;
  activeEditor?: any;
}

export default function RequestSignaturesModal({
  isOpen = false,
  setOpen,
  document,
  name = 'file',
  order,
  activeEditor,
}: RequestSignaturesModalProps) {
  const [signEmails, setSignEmails] = React.useState('');
  const [viewEmails, setViewEmails] = React.useState('');
  const [uploading, setUploading] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const notify = useNotify();
  const [signS3] = useMutation(SIGN_S3);

  // aquí hay que incluir función para lo del envío del doc a firmamex. se tienen que validar los correos primero
  const shareDocument = async () => {
    try {
      let isValidSign = true;
      let isValidView = true;
      let signEmailsList = [];
      let viewEmailsList = [];
      if (signEmails !== '') {
        signEmailsList = signEmails.split(',').map((email) => {
          if (!validateEmail(email)) {
            notify(`El correo ${email} es inválido`, 'error');
            isValidSign = false;
          }
          return email;
        });
      }

      if (viewEmails !== '') {
        viewEmailsList = viewEmails.split(',').map((email) => {
          if (!validateEmail(email)) {
            notify(`El correo ${email} es inválido`, 'error');
            isValidView = false;
          }
          return email;
        });
      }

      if (signEmails === '' && viewEmails === '') {
        return notify(
          'Debes ingresar al menos un correo para poder enviar el documento',
          'error'
        );
      }
      if (signEmails !== '' && isValidSign) {
        const doc = generatePDF(false, signEmailsList, viewEmailsList);
      }
      if (viewEmails !== '' && isValidView) {
        // revisar
        const doc = generatePDF(false, signEmailsList, viewEmailsList);
      }
      // mandar a db lo que se tenga que mandar
    } catch (e) {
      console.log('error', e);
    }
  };

  const firmamex = async (docUrl: string) => {
    try {
      const response = await axios.post('/api/firmamex', {
        docUrl,
      });
      if (
        response.status === 200 &&
        !response.data?.error &&
        !response.data?.err
      ) {
        notify('Se ha enviado la solicitud exitosamente!', 'success');
        setSignEmails('');
        setViewEmails('');
        setOpen(false);
      } else {
        notify('Ha ocurrido un error enviando su solicitud.', 'error');
      }
    } catch (err) {
      notify(
        `Ha ocurrido un error enviando su solicitud: ${err.toJSON()}`,
        'error'
      );
    }
  };

  const generatePDF = (download, signEmailsList?, viewEmailsList?) => {
    try {
      let _document = document;
      const signaturesInfo = [];
      if (signEmailsList) {
        // reemplazar %FIRMAMEX% por 'Firmamex {correo_firmante} dibujo'
        signEmailsList.forEach((email, idx) => {
          const signatureValue = `Firmamex ${email} dibujo`;
          _document = _document.replace('%FIRMAMEX_STR%', signatureValue);
          // .replace('%DIV_ID%', `signer${idx + 1}`);
          signaturesInfo.push({
            id: `signer${idx + 1}`,
            value: signatureValue,
          });
        });
      }

      // eslint-disable-next-line new-cap
      const doc = new jsPDF('p', 'mm', 'a4');

      const _doc = doc.html(_document, {
        async callback(d) {
          // Save the PDF

          // que esto solo pase si le doy a descargar
          if (download) {
            d.save(`${name}.pdf`);
            return null;
          }
          // subir a s3
          const url = await uploadPDF(doc.output('blob'));

          // enviar a firmamex
          await Promise.resolve(firmamex(url));
          return d;
        },
        margin: [16, 16, 16, 16],
        autoPaging: 'text',
        x: 0,
        y: 0,
        width: 184, // target width in the PDF document
        windowWidth: 675, // window width in CSS pixels
      });
      return _doc;
    } catch {
      console.log('error');
    }
  };

  const uploadPDF = async (doc: Blob) => {
    let url = '';
    const id = uuid();
    const _file = {
      id,
      src: null,
      file: null,
    };

    const reader = new FileReader();
    return new Promise<string>((resolve, reject) => {
      reader.onload = async function onLoadFile(e) {
        // revisar esto
        _file.src = e.target.result;
        _file.file = new File([doc], id, { type: 'application/pdf' });
        setFile(_file);

        if (_file?.src && !_file?.src.startsWith('http')) {
          const upload = async () => {
            setUploading(true);
            NProgress.start();
            let data = null;
            try {
              data = (
                await signS3({
                  variables: {
                    data: {
                      filename: `${id}.pdf`,
                      filetype: 'application/pdf',
                    },
                  },
                })
              ).data;
            } catch (err) {
              setUploading(false);
              NProgress.done();
              reject(err);
            }
            let response = null;
            try {
              const options = {
                headers: {
                  'Content-Type': 'application/pdf',
                  'x-amz-acl': 'public-read',
                },
              } as const;

              response = await axios.put(
                data?.signS3?.signedRequest,
                _file?.file,
                options
              );
            } catch (err) {
              setUploading(false);
              NProgress.done();
              reject(err);
            }
            if (response) {
              setFile({
                src: data?.signS3?.url,
                name: `${id}.pdf`,
                id,
                file: file?.file,
              });
              url = data?.signS3?.url;
              setUploading(false);
              NProgress.done();
              resolve(url);
            } else {
              setUploading(false);
              NProgress.done();
              reject(new Error('Error uploading file'));
            }
          };
          await upload();
        }
      };
      reader.readAsDataURL(doc);
    });
  };

  const generateFile = async () => {
    try {
      const { default: pdfMake } = await import('pdfmake/build/pdfmake');
      const { default: vfsFonts } = await import('pdfmake/build/vfs_fonts');
      pdfMake.vfs = vfsFonts.pdfMake.vfs;
      // console.log(order, document);
      const docDefinition = await generateDocDefinition(order, document);
      pdfMake
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .createPdf(docDefinition)
        .open();
    } catch (err) {
      console.log(err);
    }
  };

  const tinyPdfGenerator = () => {
    // console.log(activeEditor);
    const doc = activeEditor.plugins.export.convert('clientpdf', {});
    // console.log(doc);
  };

  const parseToHtml = (_document) => {
    // make a new parser
    const parser = new DOMParser();

    // convert html string into DOM
    const doc = parser.parseFromString(_document, 'text/html');
    return doc?.body;
  };

  return (
    <Modal
      className="bg-white h-auto lg:w-1/2 md:w-4/5 w-11/12 px-4 py-4 flex flex-col"
      modalOpen={isOpen}
      closeModal={() => setOpen(false)}
    >
      <div className="">
        <h2 className="font-semibold text-lg text-center">Solicitar firmas</h2>
        <div className="p-8 w-full h-full flex flex-col justify-between gap-6">
          {uploading ? (
            <div className="w-full flex justify-center items-center">
              <TailSpin
                height={80}
                width={80}
                color="#214497"
                ariaLabel="loading"
              />
            </div>
          ) : (
            <div className="p-8 w-full h-full flex flex-col justify-between gap-6">
              <div className="w-full flex flex-col gap-5">
                <Input
                  value={signEmails}
                  onChange={(e) => setSignEmails(e.target.value)}
                  label="Agregar correos electrónicos de firmantes (en el orden de aparición de sus firmas en el documento)"
                  placeholder="Separa con comas (,) cada destinatario"
                />
                {/* <Input
              value={viewEmails}
              onChange={(e) => setViewEmails(e.target.value)}
              label="Agregar correos electrónicos de remitentes no firmantes"
              placeholder="Separa con comas (,) cada destinatario"
            /> */}
              </div>
              <Button
                className="bg-secondary-500 w-full hover:bg-opacity-80 justify-self-end"
                onClick={(e) => {
                  e.preventDefault();
                  shareDocument();
                  // generateFile();
                  // tinyPdfGenerator();
                }}
              >
                Firmar documento
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
