/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import { Button, Input, PlusIcon } from '@avila-tek/ui';
import { Promotion } from '../../../../models';
import { useCountry } from '../../../../hooks/useCountry';

interface PaymentSummaryProps {
  price: number;
  rate: number;
  legalFee: number;
  symbol: string;
  promotionCode?: string;
  setPromotionCode?: React.Dispatch<React.SetStateAction<string>>;
  getPromotion?: () => Promise<void>;
  thanks: boolean;
  promotions?: Promotion[];
  discount?: number;
  onClickStripe?: () => Promise<void>;
  name: string;
}

function PaymentSummary({
  price,
  rate,
  legalFee,
  symbol,
  promotionCode,
  setPromotionCode,
  getPromotion,
  thanks,
  promotions,
  discount,
  onClickStripe,
  name,
}: PaymentSummaryProps) {
  const { currentCountry } = useCountry();
  return (
    <div className="flex flex-col w-full gap-5 mx-5">
      <div className="w-full bg-white p-5 flex flex-col rounded-lg">
        <p className="font-text font-semibold mb-1">Resumen de compra</p>
        <p>{name}</p>
        <div className="w-full flex justify-end items-end flex-col mt-2">
          {rate ? (
            <p className=" font-text font-semibold">
              {symbol}{' '}
              {((price ?? 0) * (rate ?? 1)).toLocaleString(
                currentCountry?.name === 'México' ? 'en-US' : 'es-ES',
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}
            </p>
          ) : null}
          <p className=" font-text font-medium text-sm">
            USD{' '}
            {price?.toLocaleString(
              currentCountry?.name === 'México' ? 'en-US' : 'es-ES',
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            ) ?? 0}
          </p>
        </div>
      </div>
      {thanks === false ? (
        <div className="p-5 rounded-lg bg-white w-full gap-y-4">
          <p className="font-text font-semibold mb-5">Cupón de descuento</p>
          <div className="flex flex-row gap-x-2">
            <Input
              placeholder="Cupón de descuento..."
              value={promotionCode}
              onChange={(e) => setPromotionCode(e.target.value)}
            />
            <button
              type="button"
              className="p-2 bg-secondary-500 text-white rounded-lg hover:bg-opacity-80"
              onClick={getPromotion}
            >
              <PlusIcon className="w-6" />
            </button>
          </div>
        </div>
      ) : null}
      <div className="p-5 rounded-lg bg-white w-full gap-y-4">
        <p className="font-text font-semibold mb-5">Resumen de factura</p>
        <div className="flex flex-row w-full justify-between items-cwenter my-2">
          <p className="font-text text-base text-neutral-400">{name}</p>
          <p className="font-text text-sm font-medium text-neutral-600">
            {rate ? symbol : 'USD'}{' '}
            {((price ?? 0) * (rate ?? 1)).toLocaleString(
              currentCountry?.name === 'México' ? 'en-US' : 'es-ES',
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
          </p>
        </div>
        <div className="flex flex-row w-full justify-between items-cwenter my-2">
          <p className="font-text text-base text-neutral-400">Cargos legales</p>
          <p className="font-text text-sm font-medium text-neutral-600">
            {rate ? symbol : 'USD'}{' '}
            {((legalFee ?? 0) * (rate ?? 1)).toLocaleString(
              currentCountry?.name === 'México' ? 'en-US' : 'es-ES',
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
          </p>
        </div>
        {promotions?.length > 0 &&
          promotions?.map((promotion) => (
            <div className="flex flex-row w-full justify-between items-center my-2">
              <p className="font-text text-base text-neutral-400">
                {promotion?.code}
              </p>
              <p className="font-text text-sm font-medium text-neutral-600">
                - {rate ? symbol : 'USD'}{' '}
                {(promotion.type === 'percentage'
                  ? (price + legalFee) *
                    (promotion?.modifier / 100) *
                    (rate ?? 1)
                  : promotion?.modifier * (rate ?? 1)
                ).toLocaleString(
                  currentCountry?.name === 'México' ? 'en-US' : 'es-ES',
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </p>
            </div>
          ))}
        <div className="h-[0.5px] w-full bg-neutral-500 my-4" />
        {rate ? (
          <div className="flex flex-row justify-between">
            <p className="font-text font-semibold">Total {symbol}</p>
            <p className="font-text font-semibold">
              {(
                ((price ?? 0) +
                  (legalFee ?? 0) -
                  (isNaN(discount) ? 0 : discount ?? 0)) *
                (rate ?? 1)
              ).toLocaleString(
                currentCountry?.name === 'México' ? 'en-US' : 'es-ES',
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}
            </p>
          </div>
        ) : null}
        <div className="flex flex-row justify-between">
          <p className="font-text font-medium text-sm">Total USD</p>
          <p className="font-text font-medium text-sm">
            {(
              (price ?? 0) +
              (legalFee ?? 0) -
              (isNaN(discount) ? 0 : discount ?? 0)
            ).toLocaleString(
              currentCountry?.name === 'México' ? 'en-US' : 'es-ES',
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
          </p>
        </div>
      </div>
      {thanks === false ? (
        <Button
          className="bg-secondary-500 text-white w-full hover:bg-opacity-80 lg:flex hidden flex-row gap-x-2 justify-center"
          onClick={(e) => {
            e.preventDefault();
            onClickStripe();
          }}
        >
          <p>Pagar</p>
        </Button>
      ) : null}
    </div>
  );
}

export default PaymentSummary;
