import React from 'react';
import Thanks from '../../components/dashboard/documents/payment/Thanks';
import MainLayout from '../../components/layouts/MainLayout';

function ThankYouPage() {
  return (
    <MainLayout>
      <Thanks />
    </MainLayout>
  );
}

export default ThankYouPage;
