import { useQuery } from '@apollo/client';
import { GET_ORDER } from '../../graphql/queries';
import { Order } from '../../models';

interface UseOrderProps {
  orderId?: string;
}

export default function useOrder({ orderId }: UseOrderProps) {
  const {
    data: orderData,
    loading: orderLoading,
    refetch: refetchOrder,
  } = useQuery<{ order: Order }>(GET_ORDER, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        _id: orderId,
      },
    },
    skip: !orderId,
  });

  return {
    order: orderData?.order,
    orderLoading,
    refetchOrder,
  };
}
