import React from 'react';
import { useRouter } from 'next/router';
import { useMutation, useQuery } from '@apollo/client';
import {
  CheckCircleIcon,
  CloudArrowDownIcon,
} from '@heroicons/react/24/outline';
import { TailSpin } from 'react-loader-spinner';
import { FinalPdf, Promotion } from '../../../../models';
import { GET_FINAL_PDF, GET_PAYMENT } from '../../../../graphql/queries';
import PaymentSummary from './PaymentSummary';
import RequestSignaturesModal from './RequestSignaturesModal';
import useOrder from '../../../../hooks/document/useOrder';
import PrimaryButton from '../../../common/PrimaryButton';
import SecondaryButton from '../../../common/SecondaryButton';
import {
  ASSIGN_ORDER_PDF,
  DOWNLOAD_SIGNNOW_DOCUMENT,
} from '../../../../graphql/mutation';
import { useNotify } from '../../../../hooks';
import { generateOrderPdfUrl } from '../../../../lib/generateOrderPdfUrl';

function Thanks() {
  const router = useRouter();
  const notify = useNotify();
  const { orderId } = router.query;
  const [isOpen, setOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [downloadSignnowDocument] = useMutation(DOWNLOAD_SIGNNOW_DOCUMENT);
  const [visa, setVisa] = React.useState('');
  const [finalPdf, setFinalPdf] = React.useState('');
  const [visaFinalPdf, setVisaFinalPdf] = React.useState('');
  const [assignOrderPdf] = useMutation(ASSIGN_ORDER_PDF);

  const editorRef = React.useRef(null);

  const { order, orderLoading } = useOrder({ orderId: orderId as string });

  React.useEffect(() => {
    if (!orderLoading && order) {
      setVisa(
        `<p><img src=${order?.document?.user?.lawyer
          ?.signature}  width="158" height="97"></p>
        <p class="MsoNormal" style="line-height: 0;"><strong><span lang="EN-US" style="mso-ansi-language: EN-US;">${`${order?.document?.user?.firstName} ${order?.document?.user?.lastName}`}</span></strong></p><p class="MsoNormal" style="line-height: 0;"><strong><span lang="EN-US" style="mso-ansi-language: EN-US;">INPRE No. ${order
          ?.document?.user?.lawyer?.inpre}
        `
      );
    }
  }, [order, orderLoading]);

  React.useEffect(() => {
    if (finalPdf !== '') {
      if (order?.document?.requiresVisa && visa !== '') {
        const _finalPdf = `${visa + finalPdf}`;
        setVisaFinalPdf(_finalPdf);
      } else {
        setVisaFinalPdf(finalPdf);
      }
    }
  }, [finalPdf, visa]);

  const { data: dataPdf, loading } = useQuery<{ getFinalPdf: FinalPdf }>(
    GET_FINAL_PDF,
    {
      fetchPolicy: 'network-only',
      variables: {
        data: {
          orderId: router?.query?.orderId,
        },
      },
    }
  );

  const { data: dataPayment, loading: loadingPayment } = useQuery<{
    payment;
  }>(GET_PAYMENT, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        order: router?.query?.orderId,
        status: 'COMPLETED',
      },
    },
  });

  React.useEffect(() => {
    if (!loading && dataPdf) {
      setFinalPdf(dataPdf?.getFinalPdf?.valueTemplate);
    }
  }, [loading, dataPdf]);

  const discount = React.useMemo(() => {
    if (order?.promotions === undefined || order?.promotions.length === 0)
      return 0;
    else {
      const _discount = order?.promotions.reduce(
        (discountValue: number, promo: Promotion) =>
          discountValue +
          (promo.type === 'percentage'
            ? ((order?.document?.price + order?.document?.legalFee) *
                promo.modifier) /
              100
            : promo.modifier ?? 0),
        0
      );
      if (order?.document.price + order?.document.legalFee < _discount) {
        return order?.document.price + order?.document.legalFee;
      } else return _discount;
    }
  }, [order]);

  const handleDownloadClick = async (): Promise<void> => {
    if (order?.signnowDocumentId) {
      const { data } = await downloadSignnowDocument({
        variables: {
          data: {
            signnowDocumentId: order?.signnowDocumentId,
          },
        },
      });
      if (data?.downloadSignnowDocument?.link) {
        window.open(data?.downloadSignnowDocument?.link, '_blank');
      }
    } else {
      const response = await generateOrderPdfUrl(
        order?._id,
        assignOrderPdf,
        order.document.slug
      );
      if (response.success) {
        notify(response.message, 'success');
      } else {
        notify(response.message, 'error');
      }
    }
  };

  return (
    <>
      {loadingPayment || orderLoading ? (
        <div className="flex h-screen items-center justify-center">
          <TailSpin height={80} width={80} color="#214497" />
        </div>
      ) : (
        <div className="flex lg:flex-row flex-col justify-between w-full px-28 py-8 gap-8">
          <div className="w-full flex flex-col py-8 px-6 text-gray-900 border border-gray-200 rounded-xl bg-white">
            <div className="w-[100px] h-[100px] rounded-full bg-gradient-to-t from-gray-50 to-gray-100 flex items-center justify-center mb-8">
              <CheckCircleIcon className="w-[52px] h-[52px] text-primary-600" />
            </div>
            <h1 className="font-semibold text-3xl mb-1">
              ¡Gracias por tu orden!
            </h1>
            <p className="max-w-[640px]">
              Tu documento esta listo para usar. Vas a recibir un correo de
              confirmación con el detalle de tu orden.
            </p>

            {order?.hasSignature && order?.inviteToSign?._id ? (
              <>
                <h2 className="mt-20 font-semibold text-lg mb-3">
                  {order?.document?.name}
                </h2>
                <p className="max-w-[640px]">
                  Recibirás una notificación vía correo electrónico para
                  proceder con la firma del documento, según la siguiente
                  información suministrada en la compra:
                </p>
                <div className="flex flex-col gap-2 p-3 bg-gray-50 mt-6 rounded-md">
                  {order?.inviteToSign?.to?.map((to) => (
                    <div key={to?.email} className="flex flex-row gap-[6px]">
                      <p className="font-medium min-w-max">{`${
                        to?.role?.charAt(0)?.toUpperCase() + to?.role?.slice(1)
                      }:`}</p>
                      <p className="w-full">{`<${to?.email}>`}</p>
                    </div>
                  ))}
                  {order?.inviteToSign?.viewers?.length > 0 ? (
                    <div className="flex flex-row gap-[6px]">
                      <p className="font-medium">Lectores:</p>
                      <p className="w-full">
                        {order?.inviteToSign?.viewers
                          ?.map((viewer) => `<${viewer?.email}>`)
                          .join(', ')}
                      </p>
                    </div>
                  ) : null}
                </div>
              </>
            ) : null}
            <div className="flex flex-row gap-3 mt-12">
              {order?.hasSignature && order?.inviteToSign?._id ? null : (
                <PrimaryButton
                  onClick={() => {
                    handleDownloadClick();
                  }}
                  disabled={disabled}
                  className={disabled ? 'opacity-70' : ''}
                >
                  <CloudArrowDownIcon className="w-5 h-5 text-white" />
                  Descargar
                </PrimaryButton>
              )}

              <SecondaryButton onClick={() => router.push('/orders')}>
                Ver mis documentos
              </SecondaryButton>
            </div>
          </div>
          <div className="">
            <PaymentSummary
              price={dataPayment?.payment?.amount}
              rate={
                order?.document?.user?.lawyer?.firm?.country?.currency?.rate
              }
              legalFee={order?.document?.legalFee}
              symbol={
                order?.document?.user?.lawyer?.firm?.country?.currency?.symbol
              }
              name={order?.document?.name}
              promotions={order?.promotions}
              discount={discount}
              thanks
            />
          </div>
          <RequestSignaturesModal
            isOpen={isOpen}
            setOpen={setOpen}
            document={visaFinalPdf}
            name={order?.code ?? ''}
            order={order}
          />
        </div>
      )}
    </>
  );
}

export default Thanks;
