import { Order } from '../models';

export const generateDocDefinition = async (order: Order, finalPdf: string) => {
  const { default: htmlToPdfmake } = await import('html-to-pdfmake');
  const html = htmlToPdfmake(finalPdf);

  if (order?.document?.requiresVisa) {
    return {
      content: [
        {
          columns: [
            {
              image: 'logo',
              width: 200,
              margins: [10, 10, 10, 40],
            },
          ],
        },
        html,
      ],
      images: {
        logo: order?.document?.user?.lawyer?.signature,
      },
    };
  }
  return {
    content: [html],
  };
};
