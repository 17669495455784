import nProgress from 'nprogress';
import { PDFDocument } from 'pdf-lib';

function base64ToUint8Array(base64: string) {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

export const generateOrderPdfUrl = async (
  orderId: string,
  assignOrderPdf: any,
  docName: string
) => {
  try {
    nProgress.start();
    const { data: generatedPdf } = await assignOrderPdf({
      variables: {
        data: {
          orderId,
        },
      },
    });

    if (generatedPdf) {
      const pdfBase64 = generatedPdf.downloadOrderPdf.base64Pdf;
      const pdfBytes = base64ToUint8Array(pdfBase64);

      /** Establecer los metadatos del PDF */
      const pdfDoc = await PDFDocument.load(pdfBytes);

      /**  Establecer los metadatos del PDF */
      pdfDoc.setTitle(docName);

      /** Guardar el PDF con los nuevos metadatos */
      const pdfBytesWithMetadata = await pdfDoc.save();
      const pdfBlob = new Blob([pdfBytesWithMetadata], {
        type: 'application/pdf',
      });
      const pdfUrl = URL.createObjectURL(pdfBlob);

      /** Crear el enlace de descarga */
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.setAttribute('download', `${docName}.pdf`); // Personaliza el nombre del archivo aquí
      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(pdfUrl);
      document.body.removeChild(link);

      return {
        success: true,
        message: 'El archivo se ha descargado correctamente',
      };
    } else {
      return {
        success: false,
        message: 'Ha ocurrido un error',
      };
    }
  } catch (err) {
    return {
      success: false,
      message: err.message,
    };
  } finally {
    nProgress.done();
  }
};
